import immutablePersistenceTransform from '../services/immutablepersistencetransform'
import { createTransform } from 'redux-persist'
import * as localForage from "localforage";
import * as Flatted from 'flatted';
// More info here:  https://shift.infinite.red/shipping-persistant-reducers-7341691232b1
export const transformCircular = createTransform(
  (inboundState, key) => Flatted.stringify(inboundState),
  (outboundState, key) => Flatted.parse(outboundState),
)

const REDUX_PERSIST = {
  active: true,
  reducerVersion: '1.0',
  storeConfig: {
    key: 'primary',
    storage: localForage,
    // Reducer keys that you do NOT want stored to persistence here.
    blacklist: ['login', 'nav'],
    // Optionally, just specify the keys you DO want stored to persistence.
    // An empty array means 'don't store any reducers' -> infinitered/ignite#409
    // whitelist: [],
    transforms: [transformCircular]
  }
}

export default REDUX_PERSIST
