import { createStore, applyMiddleware, compose } from 'redux'
import rehydration from '../services/rehydration'
import config from '../config/debugconfig'
import reduxpersist from '../config/reduxpersist'
import createSagaMiddleware from 'redux-saga'
import reactotron from '../config/reactotron'
import StartupActions from '../redux/startup'
import { persistStore } from 'redux-persist'
import * as localForage from "localforage";

// creates the store
export default (rootReducer, rootSaga) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = []
  const enhancers = []

  
  /* ------------- Analytics Middleware ------------- */
//   middleware.push(ScreenTracking)

  /* ------------- Saga Middleware ------------- */

  const sagaMonitor = config.useReactotron ? console.tron.createSagaMonitor() : null
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor })
  middleware.push(sagaMiddleware)

  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware))

  // if Reactotron is enabled (default for __DEV__), we'll create the store through Reactotron
  if (config.useReactotron) {
    enhancers.push(reactotron.createEnhancer())
  }
  const store = createStore(rootReducer, compose(...enhancers))

  // configure persistStore and check reducer version number
  let persistor = null
  if (reduxpersist.active) {
    const startup = () => store.dispatch(StartupActions.startup())
    persistor = persistStore(store, {manualPersist: true}, startup)
    rehydration.updateReducers(store, persistor)
  }

  // kick off root saga
  let sagasManager = sagaMiddleware.run(rootSaga)

  return {
    persistor,
    store,
    sagasManager,
    sagaMiddleware
  }
}
