import settings from '../config/settings';
import * as api from './restservices'
export const injectScript = () => {
  var script = document.createElement('script');
  script.src = "https://cdn.onesignal.com/sdks/OneSignalSDK.js";
  script.async = true;
  document.head.appendChild(script); 
}
export const registerPushNotifications = () => {
      var OneSignal = window.OneSignal || [];
      OneSignal.push(function() {
        OneSignal.init({
          appId: settings.one_signal.app_id,
          allowLocalhostAsSecureOrigin: true,
        });
      });
      OneSignal.push(function() {
        // OneSignal.showNativePrompt();
        OneSignal.showSlidedownPrompt();
      });
        OneSignal.push(function() {
          OneSignal.on('notificationPermissionChange', function(permissionChange) {
            var currentPermission = permissionChange.to;
            console.log('New permission state:', currentPermission);
          });
        });
      
        OneSignal.push(function() {
          // Occurs when the user's subscription changes to a new value.
          OneSignal.on('subscriptionChange', function (isSubscribed) {
            console.log("The user's subscription state is now:", isSubscribed);
          });
        });
        OneSignal.push(function() {
          OneSignal.on('addListenerForNotificationOpened', function (data) {
            console.log('Notification Clicked');
          });
        });
        OneSignal.push(function() {
          console.log('Subscribing')
          OneSignal.once('notificationDisplay', function (event) {
              console.log('Notifiation Displayed');
          });
        });
        OneSignal.push(function() {
          OneSignal.on('popoverShown', function (event) {
              console.log('Slide Prompt Shown');
          });
        });
        OneSignal.push(function() {
          OneSignal.on('notificationDismiss', function (event) {
              console.log('Notification Dismissed');
          });
        });
        
      
      
    OneSignal.push(function() {
      /* These examples are all valid */
      OneSignal.getUserId(function(userId) {
        console.log("OneSignal User ID:", userId);
        if (localStorage.getItem("token"))
          api.updateCustomer({one_signal: {player_id: userId}})
        // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316    
      });
    });
    
    OneSignal.push(function() {
      OneSignal.isPushNotificationsEnabled().then(function(isEnabled) {
        if (isEnabled)
          console.log("Push notifications are enabled!");
        else
          console.log("Push notifications are not enabled yet.");      
      });
    });
    // OneSignal.push(function() {
      // OneSignal.sendSelfNotification();
    // });
    
        
}

  