import {createReducer, createTypes, createActions} from 'reduxsauce'
import Immutable from 'immutable'
const { Types, Creators } = createActions({
    updateModifier: ['modifier'],
    addNote: ['note'],
    increment: null,
    decrement: null,
    setQuantity: ['quantity'],
    clearOrderItem: null
  })

export const OrderItemTypes = Types
export default Creators

const INITIAL_STATE = { 
    orderItem: null,
    modifiers: [],
    note: '',
    quantity: 1
}
// update modifier
export const updateModifier = (state, {modifier}) => {
    let modifiers = [...state.modifiers]
    let found = modifiers.findIndex(item => item._id === modifier._id)
    if (found > -1) 
        modifiers.splice(found, 1, modifier)
    else
        modifiers.push(modifier)
    return {...state, modifiers}
}

// add note
export const addNote = (state, {note}) => { return {...state, note} }
// set quantity
export const setQuantity = (state, {quantity}) => { return {...state, quantity }}
// increment
export const increment = (state) => { return {...state, quantity: state.quantity + 1 }}
// decrement
export const decrement = (state) => {
    let quantity = state.quantity
    quantity = quantity - 1
    if (quantity < 1)
        quantity = 1
    return {...state, quantity }
}
export const clearOrderItem = (state) => INITIAL_STATE // { return {...state, note: '', quantity: 1}}

export const reducer = createReducer(INITIAL_STATE, {
    [Types.UPDATE_MODIFIER]: updateModifier,
    [Types.ADD_NOTE]: addNote,
    [Types.INCREMENT]: increment,
    [Types.DECREMENT]: decrement,
    [Types.SET_QUANTITY]: setQuantity,
    [Types.CLEAR_ORDER_ITEM]: clearOrderItem
})