import React, {useContext, useEffect} from 'react'
import { ThemeContext } from '../context';
import { getImageUrl } from '../helpers';
const Theme = () => {
    let theme = useContext(ThemeContext)
    useEffect(() => {
        var bodyStyles = document.body.style;
        bodyStyles.setProperty('--cover-photo', `url(${theme.bgImage})`);
        bodyStyles.setProperty('--primary-bg-color', theme.primaryBgColor);
        bodyStyles.setProperty('--secondary-bg-color', theme.secondaryBgColor);
        bodyStyles.setProperty('--tag-color', theme.tagColor);
        bodyStyles.setProperty('--tag-txt-color', theme.tagBgColor)
    })
    return null
}
export default Theme