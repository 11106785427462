import immutable from 'seamless-immutable'
import DebugConfig from './debugconfig'
import reactotron, {trackGlobalErrors} from 'reactotron-react-js'
import { reactotronRedux as reduxPlugin } from 'reactotron-redux'
import sagaPlugin from 'reactotron-redux-saga'

if (DebugConfig.useReactotron) {

  // https://github.com/infinitered/reactotron for more options!
  reactotron
    .configure({ name: 'Ignite App' })
    .use(reduxPlugin({ onRestore: immutable }))
    .use(sagaPlugin())
    .use(trackGlobalErrors())
    .connect()

  // Let's clear Reactotron on every time we load the app
  reactotron.clear()

  // Totally hacky, but this allows you to not both importing reactotron-react-js
  // on every file.  This is just DEV mode, so no big deal.
  console.tron = reactotron
}
export default reactotron

