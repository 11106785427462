import React, { useContext } from 'react'
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";
import { ThemeContext } from '../../context';
import styled from 'styled-components';

const override = css`
    width: 162px;
    height: 162px;
    position: absolute;
    left: calc(50% - 81px);
    top: calc(50% - 81px);
`;
const Div = styled.div`
    width: 100vh;
    height: 100vh;
`;

const Loader = props => {
    let theme = useContext(ThemeContext);
    return (
        <Div>
        <PulseLoader
        css={override}
        size={50}   
        margin={2}
        color={'#444'}
        loading={props.loading}
        />
    </Div>)
}
export default Loader;