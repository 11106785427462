import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import "./App.css";
import firebase from "firebase/app";
import "firebase/auth";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import { applyMiddleware } from "redux";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { config } from "./firebase";
import createStore, { reducers } from "./redux";
import "./extensions";
import Loader from "./components/loader";
import Venue from "./containers/venue";
import NotFound from "./containers/notfound";
import Error from "./containers/error";
import Snackbar from "./components/snackbar";
import * as Sentry from "@sentry/react";
import moment from "moment";
import "moment-timezone";
import Reactotron from "reactotron-react-js";

// import { middleware } from './config/reduxpersist'
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});
// create our store
const store = createStore(
  reducers,
  sentryReduxEnhancer
  // applyMiddleware(middleware)
);
function FallbackComponent() {
  return <div>An error has occured</div>;
}
console.log(`You are running this application in ${process.env.NODE_ENV}`);
function app() {
  Reactotron.log("In App");
  return (
    // theming and order
    <FirebaseAuthProvider firebase={firebase} {...config}>
      <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
          <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
            <Router>
              <ToastProvider
                components={{ Toast: Snackbar }}
                placement="bottom-center"
              >
                <Suspense fallback={<Loader />}>
                  <Route path="/notfound">
                    <NotFound />
                  </Route>
                  <Route path="/error">
                    <Error />
                  </Route>
                  <Venue />
                </Suspense>
              </ToastProvider>
            </Router>
          </Sentry.ErrorBoundary>
        </PersistGate>
      </Provider>
    </FirebaseAuthProvider>
  );
}

export default app;
