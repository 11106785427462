
import {createReducer, createTypes, createActions} from 'reduxsauce'
const { Types, Creators } = createActions({
    updateVenueId: ['venueId'],
    updateVenue: ['venue'],
    clearVenue: null
  })

export const CardTypes = Types
export default Creators

const INITIAL_STATE = { 
    venueId: null,
    venue: null
}
// update venue id
export const updateVenueId = (state, {venueId}) => { return {...state, venueId } }
// update venue
export const updateVenue = (state, {venue}) => { return {...state, venue } }

export const clearVenue = (state) => { return INITIAL_STATE }
export const reducer = createReducer(INITIAL_STATE, {
    [Types.UPDATE_VENUE_ID]: updateVenueId,
    [Types.UPDATE_VENUE]: updateVenue,
    [Types.CLEAR_VENUE]: clearVenue
})