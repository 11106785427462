import { combineReducers } from 'redux'
import { persistReducer, createTransform } from 'redux-persist'
import configureStore from './createstore'
import rootSaga from '../sagas'
import reduxpersist from '../config/reduxpersist'

/* ------------- Assemble The Reducers ------------- */
export const reducers = combineReducers({
  order: require('./order').reducer,
  orderitem: require('./orderitem').reducer,
  payment: require('./payment').reducer,
  search: require('./search').reducer,
  user: require('./user').reducer,
  // tip: require('./tip').reducer,
  venue: require('./venue').reducer
})


export default () => {
  let finalReducers = reducers
  // If rehydration is on use persistReducer otherwise default combineReducers
  if (reduxpersist.active) {
    const persistConfig = reduxpersist.storeConfig
    finalReducers = persistReducer(persistConfig, reducers)
  }

  let { persistor, store, sagasManager, sagaMiddleware } = configureStore(finalReducers, rootSaga)

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./').reducers
      store.replaceReducer(nextRootReducer)

      const newYieldedSagas = require('../sagas').default
      sagasManager.cancel()
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware.run(newYieldedSagas)
      })
    })
  }

  return {
    persistor,
    store
  }
}
