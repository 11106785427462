export default {
  api_url: process.env.REACT_APP_API_URL,
  stripe: {
    publishable_key: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  },
  one_signal: {
    app_id: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
  },
  firebase: JSON.stringify(process.env.REACT_APP_FIREBASE_CONFIG),
  modifier_types: {
    normal: "Choose any of as many",
    single: "Choose only one",
    multiple: "Choose one of many",
    logical: "Logic Choice",
  },
  modifier_types_old: {
    normal: "Normal",
    single: "Single Selection",
    multiple: "Multiple Selection",
    logical: "Logical",
  },
  modifier_pricing_strategy: {
    use_item: "Use Item Prices",
    set_zero: "Set to zero",
    new_price: "Set New Price",
  },
  order_types: {
    dine_in: "live-order",
    pick_up: "collection",
  },
  order_statuses: {
    new: "new",
    preparing: "preparing",
    ready: "ready",
    completed: "completed",
    cancelled: "cancelled",
    failed: "failed",
    deferred: "deferred",
  },
  tax: 20,
  toast_duration: 3000,
  asap_offset: 20,
};
