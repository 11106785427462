import {createReducer, createTypes, createActions} from 'reduxsauce'
import * as Immutable from 'immutable'
const { Types, Creators } = createActions({
    updateReceiveOffers: ['receive'],
    updateAccessToken: ['access_token'],
    updateRefreshToken: ['refresh_token'],
    updateUser: ['user'],
    updateCountry: ['country'],
    updateName: ['name'],
    updateEmail: ['email'],
    updatePhone: ['phone'],
    updatePassword: ['password'],
    updateDob: ['dob'],
  })

export const UserTypes = Types
export default Creators

const INITIAL_STATE = { 
    receive_offers: false,
    access_token: '',
    refresh_token: '',
    user: null,
    country: {
        countryCode: 'gb',
        code: '44',
        country: 'United Kingdom'
    },
    name: null,
    email: null,
    phone: null,
    password: null,
    dob: null
}
// update receive offers
export const updateReceiveOffers = (state, {receive}) => { return {...state, receive } }
// update access_token
export const updateAccessToken = (state, {access_token}) => { return {...state, access_token } }
// update refresh_token
export const updateRefreshToken = (state, {refresh_token}) => { return {...state, refresh_token } }
// update user
export const updateUser = (state, {user}) => { return {...state, ...user } }
// update country
export const updateCountry = (state, {country}) => { return {...state, country } }
// update phone
export const updatePhone = (state, {phone}) => { return {...state, phone } }
// update email
export const updateEmail = (state, {email}) => { return {...state, email } }
// update password
export const updatePassword = (state, {password}) => { return {...state, password } }
// update name
export const updateName = (state, {name}) => { return {...state, name } }
// update dob
export const updateDob = (state, {dob}) => { return {...state, dob } }

export const reducer = createReducer(INITIAL_STATE, {
    [Types.UPDATE_RECEIVE_OFFERS]: updateReceiveOffers,
    [Types.UPDATE_REFRESH_TOKEN]: updateRefreshToken,
    [Types.UPDATE_ACCESS_TOKEN]: updateAccessToken,
    [Types.UPDATE_USER]: updateUser,
    [Types.UPDATE_COUNTRY]: updateCountry,
    [Types.UPDATE_PHONE]: updatePhone,
    [Types.UPDATE_EMAIL]: updateEmail,
    [Types.UPDATE_PASSWORD]: updatePassword,
    [Types.UPDATE_NAME]: updateName,
    [Types.UPDATE_DOB]: updateDob,
})