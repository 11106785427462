import { takeLatest, all } from 'redux-saga/effects'
import api from '../services/restservices'
// import fixturesapi from '../services/fixturesapi'
import debugconfig from '../config/debugconfig'

/* ------------- Types ------------- */

// import { StartupTypes } from '../Redux/StartupRedux'

/* ------------- Sagas ------------- */

// import { startup } from './StartupSagas'

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
// const api = debugconfig.useFixtures ? fixtureapi : api.create()

/* ------------- Connect Types To Sagas ------------- */

export default function * root () {
  yield all([
    // some sagas only receive an action
    // takeLatest(StartupTypes.STARTUP, startup),

    // some sagas receive extra parameters in addition to an action
    //takeLatest(GithubTypes.USER_REQUEST, getUserAvatar, api)
  ])
}
