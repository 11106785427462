import { create } from "apisauce";
import settings from "../config/settings";

// define the api
const api = create({
  baseURL: settings.api_url, // 'https://tamu-staging.herokuapp.com/v1', //'https://tamu-staging.herokuapp.com/v1', 'http://api-local.tamuapp.com:8888/v1', //
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

export const setAccessToken = (token) =>
  api.setHeaders({
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  });
api.addResponseTransform((response) => {
  if (!response.ok && response.status === 401) {
    localStorage.setItem("token", null);
    localStorage.setItem("authenticated", false);
  }
});
export const getFilters = () => api.get("/wl/list/filters");
export const getMenus = (id, zoneId) =>
  api.get(`/wl/list/menu/${id}?zone=${zoneId}`);
export const getTheme = (id, location) =>
  api.get(`/wl/template/${id}/${location}`);
export const getMenu = (id, venue) =>
  api.get(`/wl/menu/${id}?location=${venue}`);
export const getVenues = (company) => api.get(`/wl/venues?company=${company}`);
export const getVenue = (id) => api.get(`/wl/venue/${id}`);
export const getCategories = () => api.get("/wl/list/categories");
export const getCategory = (id, venue) =>
  api.get(`/wl/categories/${id}?location=${venue}`);
export const removeDuplicates = (id) => api.get(`/wl/product/duplicates`);
export const getLanding = (id) => api.get(`/venue/menu/all/${id}`);
export const getProduct = (id, location) =>
  api.get(`/wl/product/details/${id}?location=${location}`);
export const sendMagicLink = (phone, url, venue, location) =>
  api.get(`/wl/magicLink/${phone}/${url}?venue=${venue}&location=${location}`);
export const verifyMagicLink = (link) => api.get(`/wl/verifyLink/${link}`);
export const sendVerificationCode = (phone, url, venue, location) =>
  api.get(`/wl/genCode/${phone}/${url}?venue=${venue}&location=${location}`);
export const verifyCode = (phone, code) =>
  api.get(`/wl/verifyCode/${phone}/${code}`);
export const getOrder = (id) => api.get(`/wl/order/${id}`);
export const makeOrder = (order) => api.post(`/wl/order`, order);
export const updateOrder = (id, order) => api.put(`/wl/order/${id}`, order);
export const cancelOrder = (id) => api.put(`/wl/order/cancel/${id}`);
export const getPaymentIntent = () => api.get("/wl/payment/setup/intent");
export const getTransactions = () => api.get(`/wl/list/transactions`);
export const getPaymentMethods = () => api.get(`/wl/list/paymentmethods`);
export const getTable = (table, venue, location) =>
  api.get(`/wl/table/${table}?venue=${venue}&location=${location}`);
export const updateCustomer = (update) => api.post(`/wl/customer`, update);
export const search = (key, location, allergens, dietaries) =>
  api.post(`/wl/product/search`, { key, location, allergens, dietaries });
export const getOrderPaymentIntent = (id) => api.get(`/wl/order/pay/${id}`);
export const createOrder = (order) => api.post("/wl/order/create", order);
export const confirmOrder = (order, payment) =>
  api.post("/wl/order/confirm", { order, payment });
