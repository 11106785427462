import React, { lazy, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Reactotron from "reactotron-react-js";
import ScrollToTop from "../../components/scrolltotop";
import settings from "../../config/settings";
import { AuthContext, ThemeContext, VenueContext } from "../../context";
import { firebase } from "../../firebase";
import { getImageUrl, isDesktop } from "../../helpers";
import VenueActions from "../../redux/venue";
import {
  injectScript,
  registerPushNotifications,
} from "../../services/notifications";
import * as api from "../../services/restservices";
import Theme from "../../services/theme";

const Seat = lazy(() => import("../seat"));
const Main = lazy(() => import("../main"));
const Auth = lazy(() => import("../auth"));

const log = (...args) => {
  Reactotron.log(...args);
};

const Venue = (props) => {
  Reactotron.log("In venue");
  let version = 4;
  let history = useHistory();
  let [loading, setLoading] = useState(false);
  let [venue, setVenue] = useState(props.venue);
  let [scriptAdded, setScriptAdded] = useState(false);
  let [pushRegistered, setPushRegistered] = useState(false);
  let isAuthenticated = localStorage.getItem("authenticated")
    ? JSON.parse(localStorage.getItem("authenticated"))
    : false;
  let qparams = new URLSearchParams(window.location.search);
  let [theme, setTheme] = useState({
    bgImage: "",
    primaryBgColor: "#fff",
    primaryTxtColor: "#fff",
    secondaryBgColor: "#fff",
    secondaryTxtColor: "#fff",
    tagBgColor: "#fff",
    tagTxtColor: "#fff",
    logo: "",
  });
  if (isAuthenticated && !scriptAdded) {
    setTimeout(injectScript, 3000);
    setScriptAdded(true);
  }
  useEffect(() => {
    Reactotron.log("Location", window.location, qparams);
  }, []);
  useEffect(() => {
    let id = props.venueId;
    let comps = history.location.pathname.split("/");
    let reload = false;
    let code = "";
    if (
      comps.indexOf("landing") >= 0 ||
      comps.indexOf("verify") >= 0 ||
      comps.indexOf("menu") >= 0
    ) {
      let _id = comps[comps.length - 1];
      if (comps.indexOf("verify") >= 0) code = comps[comps.length - 2];
      if (
        !_id.includes("landing") &&
        !_id.includes("verify") &&
        !_id.includes("menu")
      ) {
        id = _id;
        reload = true;
      } else {
        // id = null
      }
    }
    // && reload
    if (id) {
      if (!loading) {
        setLoading(true);
        api.getVenue(id).then((response) => {
          if (response.ok) {
            setVenue(response.data);
            if (qparams.get("preview")) {
              var themeRef = firebase
                .database()
                .ref(`theme/${response.data._id}`);
              themeRef.on(
                "value",
                (snapshot) => {
                  let val = snapshot.val();
                  if (val) setTheme(val);
                },
                (err) => {
                  console.error(err);
                }
              );
            }
            props.updateVenue(response.data);
            props.updateVenueId(id);

            // if (comps.indexOf('verify') >= 0) history.push("/verify/" + code)
            // else history.push("/menu")
          } else {
            setVenue(null);
            history.push("/error");
          }
          setLoading(false);
        });
      }
    }
  }, []);
  useEffect(() => {
    if (venue) {
      // if (!theme) {
      api.getTheme(venue.venue._id, venue._id).then((response) => {
        if (response.ok) {
          let resp = response.data;
          setTheme({
            logo: checkEmpty(resp.logo)
              ? getImageUrl(resp.logo)
              : "/img/logo_dark.svg",
            bgImage: checkEmpty(resp.coverPhoto)
              ? `${getImageUrl(resp.coverPhoto)}`
              : "/img/coal_background.jpg",
            primaryBgColor: checkEmpty(resp.primaryBackgroundColor)
              ? resp.primaryBackgroundColor
              : "#3E3F68",
            primaryTxtColor: checkEmpty(resp.primaryTextColor)
              ? resp.primaryTextColor
              : "#fff",
            secondaryBgColor: checkEmpty(resp.secondaryBackgroundColor)
              ? resp.secondaryBackgroundColor
              : "#e7eaec",
            secondaryTxtColor: checkEmpty(resp.secondaryTextColor)
              ? resp.secondaryTextColor
              : "#000000",
            tagBgColor: checkEmpty(resp.tagBackgroundColor)
              ? resp.tagBackgroundColor
              : "#5dfdcb",
            tagTxtColor: checkEmpty(resp.tagTextColor)
              ? resp.tagTextColor
              : "#000000",
          });
        }
      });
    }

    // }
  }, [venue]);
  useEffect(() => {
    if (isAuthenticated && !pushRegistered) {
      setTimeout(registerPushNotifications, settings.toast_duration);
      setPushRegistered(true);
    }
  }, [isAuthenticated, pushRegistered]);
  const checkEmpty = (value) => {
    return value && value.length > 0;
  };
  return theme && venue ? (
    <ThemeContext.Provider value={theme}>
      <AuthContext.Provider value={isAuthenticated}>
        <VenueContext.Provider value={venue}>
          <ScrollToTop />
          <Theme />
          <Auth />
          {/* <Seat /> */}
          <Main />
          <link
            href="https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,600,700&display=swap"
            rel="stylesheet"
          />
          <link rel="stylesheet" href={`/light.css?v=${version}`} />
          <link
            rel="stylesheet"
            href={`/${isDesktop() ? "styled" : "style"}.css?v=${version}`}
          />
          <link rel="stylesheet" href={`/slick.min.css?v=${version}`} />
          <link rel="stylesheet" href={`/generator.css?v=${version}`} />
        </VenueContext.Provider>
      </AuthContext.Provider>
    </ThemeContext.Provider>
  ) : null;
};
const mapStateToProps = (state) => ({
  venueId: state.venue.venueId,
  venue: state.venue.venue,
});
const mapDispatchToProps = (dispatch) => ({
  updateVenueId: (venueId) => dispatch(VenueActions.updateVenueId(venueId)),
  updateVenue: (venue) => dispatch(VenueActions.updateVenue(venue)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Venue);
