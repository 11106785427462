
import {createReducer, createTypes, createActions} from 'reduxsauce'
const { Types, Creators } = createActions({
    updateDietaries: ['dietaries'],
    updateAllergens: ['allergens'],
    updateSearch: ['text']
  })

export const CardTypes = Types
export default Creators

const INITIAL_STATE = { 
    dietaries: [],
    allergens: [],
    text: ''
}
// update dietaries
export const updateDietaries = (state, {dietaries}) => { return {...state, dietaries: [...dietaries] } }
// update allergens
export const updateAllergens = (state, {allergens}) => { return {...state, allergens: [...allergens] } }
// update text
export const updateSearch = (state, {text}) => { return {...state, text } }

export const reducer = createReducer(INITIAL_STATE, {
    [Types.UPDATE_DIETARIES]: updateDietaries,
    [Types.UPDATE_ALLERGENS]: updateAllergens,
    [Types.UPDATE_SEARCH]: updateSearch
})