import React from 'react'

const Snackbar = ({
  appearance,
  children,
  transitionDuration,
  transitionState,
  onDismiss
}) => {
    return(<div className={appearance === 'error' ? 'error_toast' : 'success_toast'}>
    <img src={`/img/icons8-${appearance === 'error' ? 'cancel' : 'checkmark-yes'}.svg`} alt="" />
    {children}
  </div>)
}
export default Snackbar;