import * as firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/database'


const config = process.env.NODE_ENV === 'development' ? {
    apiKey: "AIzaSyAxFno7kuii90HnaIy-p-o2K4QEHL_MB3M",
    authDomain: "tamu-stage.firebaseapp.com",
    databaseURL: "https://tamu-stage.firebaseio.com",
    projectId: "tamu-stage",
    storageBucket: "tamu-stage.appspot.com",
    messagingSenderId: "463058089165",
    appId: "1:463058089165:web:f97f7ab9a01efc4a672584",
    measurementId: "G-PCYNP0L68X"
} : JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

firebase.initializeApp(config);

firebase.auth();

export {
    firebase,
    config
};