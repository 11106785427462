import {createReducer, createTypes, createActions} from 'reduxsauce'
import Immutable from 'immutable'
import settings from '../config/settings'
import {
    isBrowser
} from 'react-device-detect'
import { isDesktop } from '../helpers'
const { Types, Creators } = createActions({
    updateInCourses: ['inCourses'],
    updateOrderId: ['orderId'],
    updateTime: ['time'],
    updateTip: ['tip'],
    updateTable: ['table'],
    selectMenu: ['menu'],
    selectCategory: ['category'],
    addOrderItem: ['orderItem'],
    updateOrderItem: ['orderItem'],
    removeOrderItem: ['orderItem'],
    applyDiscount: ['discount'],
    updateOrderType: ['orderType'],
    updatePaymentMethod: ['method'],
    updateCard: ['card'],
    updateIsAsap: ['asap'],
    clearOrder: null
})

export const OrderTypes = Types
export default Creators

const INITIAL_STATE = { 
    inCourses: false,
    orderId: null,
    time: new Date(),
    table: null,
    menu: null,
    category: null,
    orderItems: [],
    discount: null,
    method: 'card',
    card: null,
    tip: 0,
    asap: true,
    orderType: isDesktop() ? settings.order_types.pick_up :settings.order_types.dine_in
}
// update in courses or at once
export const updateInCourses = (state, {inCourses}) => { return {...state, inCourses}}
// update is asap
export const updateIsAsap = (state, {asap}) => { return {...state, asap} }
// update order id
export const updateOrderId = (state, {orderId}) => { return {...state, orderId} }
// update time
export const updateTime = (state, {time}) => { return {...state, time} }
// update tip
export const updateTip = (state, {tip}) => { return {...state, tip} }
// update payment method
export const updatePaymentMethod = (state, {method}) => { return {...state, method} }
// update card
export const updateCard = (state, {card}) => { return {...state, card} }
// update order type
export const updateOrderType = (state, {orderType}) => { return {...state, orderType} }
// update table pin
export const updateTable = (state, {table}) => { return {...state, table} }
// select menu
export const selectMenu = (state, {menu}) => { return {...state, menu} }
// select category
export const selectCategory = (state, { category }) => { return {...state, category} }
// add order item w/ user
export const addOrderItem = (state, {orderItem}) => { 
    let newState = {...state, orderItems: [...state.orderItems, orderItem]}
    console.log(newState)
    return newState
}
// update order item w/ user
export const updateOrderItem = (state, {orderItem}) => { return {...state, orderItems: [...state.orderItems.filter(item => item.orderItemId !== orderItem.orderItemId), orderItem]} }
// remove order item
export const removeOrderItem = (state, {orderItem}) => { return {...state, orderItems: state.orderItems.filter(item => item.orderItemId !== orderItem.orderItemId)} }
// apply discount
export const applyDiscount = (state, {discount}) => { return {...state, discount} }
// clear
export const clearOrder = (state) => {
    
    let update = {
        orderItems: [],
        inCourses: false,
        time: new Date(),
        discount: null,
        orderId: null,
        tip: 0,
        asap: true
    }
    if (isDesktop()) {
        update = {...update, orderType: settings.order_types.pick_up}
    }
    return {...state, ...update}
}

export const reducer = createReducer(INITIAL_STATE, {
    [Types.UPDATE_ORDER_ID]: updateOrderId,
    [Types.UPDATE_IN_COURSES]: updateInCourses,
    [Types.UPDATE_CARD]: updateCard,
    [Types.UPDATE_TIME]: updateTime,
    [Types.UPDATE_TIP]: updateTip,
    [Types.UPDATE_PAYMENT_METHOD]: updatePaymentMethod,
    [Types.UPDATE_ORDER_TYPE]: updateOrderType,
    [Types.UPDATE_TABLE]: updateTable,
    [Types.SELECT_MENU]: selectMenu,
    [Types.SELECT_CATEGORY]: selectCategory,
    [Types.ADD_ORDER_ITEM]: addOrderItem,
    [Types.UPDATE_ORDER_ITEM]: updateOrderItem,
    [Types.REMOVE_ORDER_ITEM]: removeOrderItem,
    [Types.APPLY_DISCOUNT]: applyDiscount,
    [Types.UPDATE_IS_ASAP]: updateIsAsap,
    [Types.CLEAR_ORDER]: clearOrder
})