import moment from "moment";
import { isBrowser, isMobile } from "react-device-detect";
import { orderTotal } from "../calculators/pricing";
export const prepareOrderEdit = (order) => {
  var orderItems = [];
  for (var i in order.items) {
    var item = order.items[i];
    var modifiers = [];
    for (var j in item.modifiers) {
      var modifier = item.modifiers[j];
      for (var k in modifier.options) {
        var option = modifier.options[k];
        modifiers.push({
          modifier: modifier._id,
          option: { ...option, quantity: option.quantity },
          quantity: option.quantity,
          _id: option.original ? option.original : option._id,
        });
      }
    }
    orderItems.push({
      orderItemId: item._id,
      _id: item._id,
      productId: item.product._id,
      product: item.product,
      quantity: item.quantity,
      note: item.note,
      modifiers,
    });
  }
  return orderItems;
};
export const prepareOrderPost = (order) => {
  let total = orderTotal(order);

  let orderItems = [];
  let note = "";
  // Create order items
  for (var i in order.orderItems) {
    var orderItem = order.orderItems[i];
    let unitAmount =
      orderItem.product.price * orderItem.quantity +
      orderItem.modifiers.reduce((subtotal, modifier) => {
        return subtotal + modifier.option.price * modifier.quantity;
      }, 0);
    let modifiers = orderItem.modifiers.reduce((map, option) => {
      map[option.modifier._id] = {
        modifier: option.modifier._id ? option.modifier._id : option.modifier,
        options: orderItem.modifiers
          .filter((inner) => inner.modifier._id === option.modifier._id)
          .map((item) => {
            return {
              original: item.option._id,
              product: orderItem.productId,
              imageUrl: item.option.imageUrl,
              title: item.option.title,
              price: item.option.price,
              quantity: item.quantity,
            };
          }),
      };
      return map;
    }, {});
    note += `[${orderItem.product.name}, ${
      Object.keys(modifiers).length
    }xSides]${orderItem.note}\n`;
    orderItems.push({
      note: orderItem.note,
      customer: order.customer,
      product: orderItem.productId,
      modifiers: Object.values(modifiers),
      unitAmount: unitAmount,
      quantity: orderItem.quantity,
    });
  }
  return {
    inCourses: order.inCourses,
    customer: order.customer,
    scheduledAt: order.scheduledAt,
    currentAmount: total,
    type: order.type,
    tip: order.tip,
    note: note,
    venue: order.venue,
    location: order.location,
    table: order.table,
    discountPercentage: order.discountPercentage,
    orderItems,
  };
};
export const isDesktop = () => {
  return false; // isBrowser
};

export const serviceTypes = (venue, settings) => {
  let types = [];
  if (isDesktop()) {
    // Check collection
    if (venue.collectionOrder) {
      if (venue.collectionOrder.enabled || venue.collectionOrder.alwaysOpen)
        types = [settings.order_types.pick_up];
    } else if (venue.services)
      if (
        venue.services.some(
          (service) =>
            service.type === settings.order_types.pick_up &&
            service.status === "active"
        )
      )
        types = [settings.order_types.pick_up];
  } else {
    if (venue.collectionOrder || venue.liveOrder) {
      if (venue.collectionOrder) {
        if (venue.collectionOrder.enabled || venue.collectionOrder.alwaysOpen)
          types.push(settings.order_types.pick_up);
      }
      if (venue.liveOrder) {
        if (venue.liveOrder.enabled) types.push(settings.order_types.dine_in);
      }
    } else {
      types = venue.services
        ? venue.services.map((service) =>
            service.status === "active" ? service.type : undefined
          )
        : [settings.order_types.dine_in, settings.order_types.pick_up];
    }
  }
  return types;
};
export const getASAPTime = (venue, type, settings) => {
  let current = currentOrderTime(venue, type, settings);
  if (current) return moment();
  else {
    let next = nextOrderTime(0, venue, type, settings);
    return next && next.start;
  }
};
export const canOrder = (venue, type, settings) => {
  let now = moment();
  let dayOfWeek = now.day();
  if (dayOfWeek === 0) dayOfWeek = 7;
  let schedule = getSchedule(venue, type, settings);
  let today = schedule.find((item) => item.weekDay === dayOfWeek);
  let timespan = parseDay(today);
  return today
    ? timespan.some((item) => {
        let start = item.startTime;
        let end = item.endTime;
        return now.isBetween(start, end);
      })
    : false;
};
export const currentOrderTime = (venue, type, settings) => {
  let now = moment();
  let dayOfWeek = now.day();
  if (dayOfWeek === 0) dayOfWeek = 7;
  let schedule = getSchedule(venue, type, settings);
  let today = schedule.find((item) => item.weekDay === dayOfWeek);
  let timespan = parseDay(today);
  if (today) {
    let todayOpen = timespan.find((item) => {
      let start = item.startTime;
      let end = item.endTime;
      return now.isBetween(start, end);
    });
    if (todayOpen) {
      let start = todayOpen.startTime;
      let end = todayOpen.endTime;
      return { start, end };
    }
  }
  return null;
};
export const nextOrderTime = (days, venue, type, settings) => {
  console.log("Days", days);
  let now = moment().add(days, "days");
  let dayOfWeek = now.day();
  if (dayOfWeek === 0) dayOfWeek = 7;
  let schedule = getSchedule(venue, type, settings);
  let today = schedule.find((item) => item.weekDay === dayOfWeek);
  let timespan = parseDay(today);
  if (today && days === 0) {
    let todayTime = timespan.find((item) => {
      let start = item.startTime;
      let end = item.endTime;
      return now.isBetween(start, end);
    });
    if (todayTime) return { start: todayTime.startTime };
    else {
      let todayLaterTime = timespan.find((item) => {
        let start = item.startTime;
        let end = item.endTime;
        return now.isBefore(end);
      });
      if (todayLaterTime) return { start: todayLaterTime.startTime };
      else today = null;
    }
  }
  if (!today) {
    if (days < 7) return nextOrderTime(days + 1, venue, type, settings);
  } else {
    timespan.sort((itemA, itemB) => itemA.startTime < itemB.startTime);
    let nextOpen = timespan[0].startTime;
    nextOpen.weekday(dayOfWeek);
    return { start: nextOpen };
  }
};
export const isLocationMenuActive = (menu, location) => {
  if (menu.location) {
    if (menu.location.obj === location)
      return menu.location.status === "active";
  }
  return false;
};
export const getSchedule = (venue, type, settings) => {
  if (type === settings.order_types.pick_up) {
    return venue.collectionOrder
      ? venue.collectionOrder.schedule
        ? venue.collectionOrder.schedule
        : []
      : venue.schedule
      ? venue.schedule
      : [];
  } else if (type === settings.order_types.dine_in) {
    return venue.liveOrder
      ? venue.liveOrder.schedule
        ? venue.liveOrder.schedule
        : []
      : venue.schedule
      ? venue.schedule
      : [];
  } else {
    return venue.schedule ? venue.schedule : [];
  }
};
export const isMenuActive = (menu) => {
  let now = moment();
  let dayOfWeek = now.day();
  if (dayOfWeek === 0) dayOfWeek = 7;
  let today = menu.weekDays.find((item) => item.weekDay === dayOfWeek);
  if (today) {
    let timespan = parseDay(today);
    if (timespan.length > 0) {
      return timespan.some((time) =>
        now.isBetween(time.startTime, time.endTime)
      );
    }
  }
  return false;
};
export const getImageUrl = (comp) => {
  var pattern = /^(https?|ftp):\/\//;
  let test = pattern.test(comp);
  if (test) {
    return comp;
  } else {
    return comp
      ? `https://tamu-img-bucket.s3.eu-west-2.amazonaws.com/${comp}`
      : "";
  }
};
export const getTypePrice = (price, prices, type) => {
  let pric = prices && prices.find((price) => price.type === type);
  return pric ? pric.value : price;
};
export const getPrice = (price) => {
  return parseFloat(price) > 0 ? `£${parseFloat(price).toFixed(2)}` : "";
};

export const hasText = (text) => {
  return text && text.trim().length > 0;
};

export const shortenText = (text) => {
  return text.length > 100 ? text.substr(0, 100) + "..." : text;
};

export const parseDay = (day) => {
  if (day && day.timings) {
    return parseTiming(day.timings);
  } else if (day && day.timespan) {
    return day.timespan.map((time) => {
      return {
        startTime: moment(`${time.startTime}:00`, "H:mm"),
        endTime: moment(`${time.endTime}:00`, "H:mm"),
      };
    });
  } else {
    return [];
  }
};
export const parseTiming = (value) => {
  var timings = [];
  if (value === "all day") {
    return [
      {
        startTime: moment("00:00 AM", "hh:mm A"),
        endTime: moment("11:59 PM", "hh:mm A"),
      },
    ];
  } else if (value === "closed") {
    return [
      {
        startTime: moment("00:00 AM", "hh:mm A"),
        endTime: moment("00:00 AM", "hh:mm A"),
      },
    ];
  } else if (value !== "all day" && value !== "closed") {
    // Separate components
    const regex =
      /^(0?[1-9]|1[0-2]):[0-5][0-9][\s]{1}(AM|PM){1}-(0?[1-9]|1[0-2]):[0-5][0-9][\s]{1}(AM|PM){1}$/;
    const comps = value.split(",");
    for (const comp of comps) {
      if (!regex.test(comp.trim())) {
        return [];
      } else {
        const timeComps = comp.split("-");
        const startTime = moment(timeComps[0].trim(), "hh:mm A");
        const endTime = moment(timeComps[1].trim(), "hh:mm A");
        timings.push({ startTime, endTime });
        timings.sort((mom1, mom2) => mom1.startTime.diff(mom2.startTime));
      }
    }
    return timings;
  }
};

export const isOpen = (schedule) => {
  let now = moment();
  let dayOfWeek = now.day();
  if (dayOfWeek === 0) dayOfWeek = 7;
  let today = schedule.find((item) => item.weekDay === dayOfWeek);
  let timespan = parseDay(today);
  return today
    ? timespan.some((item) => {
        let start = item.startTime;
        let end = item.endTime;
        return now.isBetween(start, end);
      })
    : false;
};
