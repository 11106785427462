
import {createReducer, createTypes, createActions} from 'reduxsauce'
import Immutable from 'immutable'
const { Types, Creators } = createActions({
    updateCard: ['card']
  })

export const CardTypes = Types
export default Creators

const INITIAL_STATE = { 
    card: null
}
// update card
export const updateCard = (state, {card}) => { return {...state, card } }

export const reducer = createReducer(INITIAL_STATE, {
    [Types.UPDATE_CARD]: updateCard
})