

export const orderModifiersTotal = (product, modifiers) => {
    return modifiers.reduce((acc, option) => {
        return acc + (option.quantity > 0 ? (option.option.price * option.quantity) : 0)
      }, product.price)
}
export const orderItemTotal = (orderItem) => {
    let modifiersPrice = orderModifiersTotal(orderItem.product, orderItem.modifiers)
    return orderItem.quantity > 0 ? orderItem.quantity * modifiersPrice : modifiersPrice
}
export const orderTotal = (order) => {
    return order.orderItems.reduce((acc, orderItem) => {
        let orderPrice = orderItemTotal(orderItem)
        return acc + orderPrice
    }, 0)
}

