import ReduxPersist from '../config/reduxpersist'
import DebugConfig from '../config/debugconfig'
import * as localForage from "localforage";

const updateReducers = (store, persistor) => {
  const reducerVersion = ReduxPersist.reducerVersion
  // Check to ensure latest reducer version
  localForage.getItem('reducerVersion').then(localVersion => {
    if (localVersion !== reducerVersion) {
      if (DebugConfig.useReactotron) {
        console.tron.display({
          name: 'PURGE',
          value: {
            'Old Version:': localVersion,
            'New Version:': reducerVersion
          },
          preview: 'Reducer Version Change Detected',
          important: true
        })
      }
      // Purge store
      persistor.purge()
      localForage.setItem('reducerVersion', reducerVersion)
      persistor.persist()
    } else {
      persistor.persist()
    }
  }).catch(err => {
    persistor.persist()
    localForage.setItem('reducerVersion', reducerVersion)
  })
}

export default { updateReducers }
